<template>
  <ContainerBasic
    v-if="!error && showSubNav"
    classes="max-w-screen-xl mx-auto my-md print:hidden"
    data-testid="subNav"
  >
    <section>
      <div
        class="flex flex-col justify-between text-sm mb-xs px-sm text-primary-base gap-y-xs md:flex-row"
      >
        <div class="text-left md:text-left">
          <LinkBasic
            v-if="state.overviewEntry.link"
            data-testid="subNav-back"
            :label="`${state.overviewEntry.label}`"
            :link="state.overviewEntry.link"
            :filter-params="queryParams"
            target="_self"
            icon="fas fa-chevron-left"
            :icon-position="EPosition.LEFT"
            class="text-primary-base"
          />
        </div>
        <div
          v-if="state.nextEntry.link || state.prevEntry.link"
          class="flex justify-between space-x-0 text-center md:space-x-md md:text-right"
        >
          <LinkBasic
            v-if="state.prevEntry.link !== null"
            data-testid="subNav-prev"
            :label="`${t(state.prevEntry.label)}`"
            :link="state.prevEntry.link"
            :filter-params="queryParams"
            target="_self"
            icon="fas fa-chevron-left"
            :icon-position="EPosition.LEFT"
            class="text-primary-base"
          />
          <LinkBasic
            v-if="state.nextEntry.link !== null"
            data-testid="subNav-next"
            :label="`${t(state.nextEntry.label)}`"
            :link="state.nextEntry.link"
            :filter-params="queryParams"
            target="_self"
            icon="fas fa-chevron-right"
            class="text-primary-base"
          />
        </div>
      </div>
      <div
        v-if="
          'tags' in (pageData as any)[pageData.typeHandle] &&
          (pageData as any)[pageData.typeHandle]?.tags?.length > 0
        "
        class="flex flex-wrap content-center bg-background-base-opc50 p-sm gap-sm rounded-alt-lg"
      >
        <div
          v-for="tag in (pageData as any)[pageData.typeHandle]
            .tags as TagData[]"
          :key="tag.id"
        >
          <NuxtLink
            v-if="state.overviewEntry.link"
            class="text-center text-white cursor-pointer rounded-alt-md px-sm py-2xs bg-primary-base"
            :to="{
              path: state.overviewEntry.link,
              query: { tags: encodeURI(JSON.stringify([tag.id])) },
            }"
          >
            {{ tag.title }}
          </NuxtLink>
        </div>
      </div>
    </section>
  </ContainerBasic>
  <div v-else>
    <LoadingError />
  </div>
</template>

<script setup lang="ts">
import { PageTypeHandle } from '@/@types/type-handle';
import { EPosition } from '@/@types/basic-link';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import ContainerBasic from '@/components/components/container-basic.vue';
import type { CMSPage } from '~~/src/composables/useLoadCMSPage';
import type {
  postBody,
  Result,
} from '~~/src/server/api/[site]/content/navigation/siblings.post';
import { useCachedPost } from '~~/src/composables/dataFetching/genericFetchers.js';
import type { BaseSubPageData } from '~~/src/server/transformers/page/subPage';
import type { TagData } from '~~/src/server/transformers/EntityTransformers/utils';
import { LoadingError } from '@/complib';
import { handleLoadingError } from '~/utils/handleLoadingError';

const { t } = useTrans();

const subNav_allowedTypeHandles = [
  PageTypeHandle.EVENT,
  PageTypeHandle.POST,
  PageTypeHandle.ROOMPLANNING_REFERENCE,
  PageTypeHandle.OFFICE,
  PageTypeHandle.SERVICE,
  PageTypeHandle.AGREEMENT,
  PageTypeHandle.OFFER,
  PageTypeHandle.PERSON,
];

const props = defineProps({
  pageData: {
    type: Object as PropType<BaseSubPageData>,
    required: true,
    default: {} as CMSPage,
  },
});

const state = getState();
const error = ref(false);

const showSubNav = shouldShowSubnav();

const router = useRouter();
const queryParams = useRouter().currentRoute.value.query;

await init();

/**
 * Functions
 */
async function init() {
  if (showSubNav && props.pageData) {
    try {
      const params: postBody = getParams();
      const site = useSiteIdent();
      const data: Result = (
        await useCachedPost(`/api/${site}/content/navigation/siblings`, params)
      ).data.value;

      state.nextEntry = data.nextEntry;
      state.prevEntry = data.prevEntry;

      state.overviewEntry.link = props.pageData.parentPage?.link;
      state.overviewEntry.label = props.pageData.parentPage?.title;
    } catch (e) {
      error.value = true;
      handleLoadingError(e);
    }
  }
}

function getState(): SubNavEntry {
  return {
    nextEntry: { label: null, link: null },
    prevEntry: { label: null, link: null },
    overviewEntry: { label: null, link: null },
  };
}

function shouldShowSubnav() {
  return subNav_allowedTypeHandles.includes(props.pageData.typeHandle);
}

function getParams(): postBody {
  const urlParams = router.currentRoute.value.query;
  const params: Record<string, any> = {};
  Object.keys(urlParams).forEach((cur) => {
    try {
      params[cur] = JSON.parse(decodeURI(urlParams[cur] as string));
    } catch {
      // Do nothing
    }
  });
  return {
    params: {
      ...params,
      id: props.pageData.id,
      sectionId: props.pageData.sectionId,
      treeParent: props.pageData.parentPage?.id,
    },
    typeHandle: props.pageData.typeHandle,
    pageUri: props.pageData.uri,
  };
}

type SubNavEntry = {
  nextEntry: { label: string; link: string };
  prevEntry: { label: string; link: string };
  overviewEntry: { label: string; link: string };
};
</script>
<style scoped lang="postcss"></style>
